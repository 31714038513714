import { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Feature from "./Feature";
import { TbMap, TbMapOff } from "react-icons/tb";
import Logout from "../Utilities/Logout";
import EMap from "../Map";

const Features = ({ project }) => {
  const [data, setData] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [update, setUpdate] = useState(false);

  const token = localStorage.getItem("eapi-token");

  useEffect(() => {
    const getData = async (token, project) => {
      const project_config_url = process.env.REACT_APP_PROJECT_CONFIG_URL;

      const queryUrl = `${project_config_url}/query`;
      const url = new URL(queryUrl);

      const params = {
        where: `objectid=${project}`,
        returnGeometry: "false",
        outFields: "*",
        f: "json",
        orderByFields: "name",
        token: token,
      };

      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );

      try {
        const request = await fetch(url);
        const json = await request.json();

        if (json?.features && json?.features?.length) {
          setData(json.features[0].attributes);
        }
      } catch (error) {
        Logout();
        console.error(error);
      }
    };

    if (token || (token && update)) {
      getData(token, project);
    }
  }, [token, project, update]);

  return (
    <Card className="p-0">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title className="m-0 d-flex align-self-center">
          {data?.name}
        </Card.Title>
        <Button
          size="sm"
          title="Show\Hide Map"
          className="m-0"
          onClick={() => setShowMap(!showMap)}
        >
          {showMap ? <TbMapOff color="white" /> : <TbMap color="white" />}
        </Button>
      </Card.Header>
      <Card.Body className="m-0 pt-2 pb-1">
        <Table size="sm">
          <tbody>
            <tr>
              <th>Project ID:</th>
              <td>{data?.projectid}</td>
              <th>Client ID:</th>
              <td>{data?.clientid}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
      <Card.Body className="m-0 pt-0">
        <Table size="sm">
          <thead>
            <tr>
              <th></th>
              <th>Feature</th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Checks
              </th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <Feature
              url={data?.roadfs}
              name={data?.name}
              projectId={data?.objectid}
              title="Roads"
              type="line"
              required={true}
              update={update}
              setUpdate={setUpdate}
            />
            <Feature
              url={data?.boundaryfs}
              name={data?.name}
              projectId={data?.objectid}
              title="Boundary"
              type="polygon"
              required={true}
              update={update}
              setUpdate={setUpdate}
            />
            <Feature
              url={data?.zonefs}
              name={data?.name}
              projectId={data?.objectid}
              title="Zones"
              type="polygon"
              required={true}
              update={update}
              setUpdate={setUpdate}
            />
            <Feature
              url={data?.parcelfs}
              name={data?.name}
              projectId={data?.objectid}
              title="Parcels"
              type="polygon"
              required={false}
              update={update}
              setUpdate={setUpdate}
            />

            <Feature
              url={data?.roads_progress}
              name={data?.name}
              projectId={data?.objectid}
              title="Progress"
              type="line"
              required={false}
              update={update}
              setUpdate={setUpdate}
            />
          </tbody>
        </Table>
        {showMap && (
          <EMap
            roads={data?.roadfs}
            boundaries={data?.boundaryfs}
            zones={data?.zonefs}
            apiKey={token}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default Features;
