import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Logout = () => {
  //localStorage.clear();
  //window.location = `https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=token&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}&expires=120f=json`;

  return (
    <Modal show={true} size="lg">
      <Modal.Header>
        <Modal.Title>Thompson Consulting Services</Modal.Title>
      </Modal.Header>
      <Modal.Title className="text-center">EAPI System Login</Modal.Title>
      <Modal.Body className="d-flex align-items-center">
        <Container fluid>
          <Row
            sm={12}
            className="d-flex flex-row p-2 m-2 justify-content-center align-items-center"
          >
            <Col md={6} sm={12} className="d-flex justify-content-center">
              <Button
                className="text-center rounded float-left"
                size="lg"
                variant="outline-light"
                style={{ color: "#333" }}
              >
                <img
                  src="/enterprise.png"
                  alt="ArcGIS Enterprise Logo"
                  height={"100px"}
                />
                <div className="text-center">ArcGIS Enterprise</div>
              </Button>
            </Col>
            <Col md={6} sm={12} className="d-flex justify-content-center">
              <Button
                className="text-center rounded float-right"
                size="lg"
                variant="outline-light"
                style={{ color: "#333" }}
                onClick={() => {
                  window.location = `https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=token&redirect_uri=${process.env.REACT_APP_AGOL_REDIRECT_URL}&expires=120`;
                }}
              >
                <img
                  src="/agol.png"
                  alt="ArcGIS Online Logo"
                  height={"100px"}
                />
                <div className="text-center">ArcGIS Online</div>
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default Logout;
