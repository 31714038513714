import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import User from "./User";

const Navigation = ({ user }) => {
  return (
    <Navbar bg="dark" variant="dark">
      <Container className="d-flex align-items-center">
        <Navbar.Brand>
          <a
            href="https://thompsoncs.net"
            to="/"
            style={{ textDecoration: "none", color: "white" }}
          >
            <img
              alt="Thompson Consulting Service Logo"
              src={"./logo192.png"}
              width="35"
              height="35"
            />{" "}
            Eligibility System
          </a>
        </Navbar.Brand>
        <Navbar.Collapse></Navbar.Collapse>
        <Nav>
          <User user={user} />
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Navigation;
