import { useContext, useState } from "react";
import { Context } from "../Context";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Spinner } from "react-bootstrap";
import { MdOutlineSystemUpdateAlt } from "react-icons/md";
import { IoLayersSharp } from "react-icons/io5";
import updateFeatureClass from "../Utilities/UpdateFeatureClass";

const Layers = ({
  data,
  projectId,
  type,
  murl,
  updateField,
  setShow,
  update,
  setUpdate,
}) => {
  const [, setContext] = useContext(Context);

  const token = localStorage.getItem("eapi-token");

  const [layers, setLayers] = useState([]);
  const [layersLoading, setLayersLoading] = useState(false);
  const [error, setError] = useState(false);

  let conversion = "";
  switch (type) {
    case "polygon":
      conversion = "esriGeometryPolygon";
      break;
    case "line":
      conversion = "esriGeometryPolyline";
      break;
    default:
      conversion = "";
      break;
  }

  const RetrieveLayers = async (inputUrl) => {
    setLayersLoading(true);
    const params = {
      token: token,
      f: "json",
    };

    const url = new URL(inputUrl);

    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    try {
      const response = await fetch(url, { cache: "no-cache" });
      const json = await response.json();
      setLayersLoading(false);
      setLayers(json.layers);
    } catch (e) {
      setLayersLoading(false);
      setError("There was a problem accessing ArcGIS Online");
      console.error(error);
      return;
    }
  };

  const handleClick = async (id, projectId, murl, updateField) => {
    const data = await updateFeatureClass(id, projectId, murl, updateField);
    if (data) {
      setTimeout(() => {
        setUpdate(() => !update);
        setContext({
          head: "Success",
          message: "Updated Successfully",
          status: "success",
        });
        setShow(false);
      }, 500);
    }
  };

  if (!data) {
    return (
      <>
        {layers.length > 0 ? (
          <Table size="sm" className="my-2" style={{ width: "50%" }}>
            <thead>
              <tr>
                <th>Layer Name</th>
                <th style={{ textAlign: "center" }}>ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {layers?.map((n, idx) => {
                return (
                  <tr key={idx}>
                    <td
                      style={{
                        verticalAlign: "middle",
                        width: "85%",
                      }}
                    >
                      {n.name}
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        width: "10%",
                      }}
                    >
                      {n.id}
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                        width: "5%",
                      }}
                    >
                      <Button
                        size="sm"
                        variant={
                          !(conversion === n.geometryType) ? "dark" : "warning"
                        }
                        disabled={!(conversion === n.geometryType)}
                        style={{
                          verticalAlign: "middle",
                        }}
                        onClick={() => {
                          handleClick(n.id, projectId, murl, updateField);
                        }}
                      >
                        <MdOutlineSystemUpdateAlt color="white" />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Button
            size="sm"
            variant="info"
            className="ml-4"
            title="Retrieve Layers"
            onClick={() => {
              RetrieveLayers(murl);
            }}
          >
            <IoLayersSharp style={{ color: "white" }} />
          </Button>
        )}
      </>
    );
  }

  if (error) {
  }

  if (layersLoading) {
    return <Spinner></Spinner>;
  }

  if (data) {
    return (
      <Table size="sm" className="my-2" style={{ width: "50%" }}>
        <thead>
          <tr>
            <th>Layer Name</th>
            <th style={{ textAlign: "center" }}>ID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((n, idx) => {
            return (
              <tr key={idx}>
                <td
                  style={{
                    verticalAlign: "middle",
                    width: "85%",
                  }}
                >
                  {n.title}
                </td>

                <td
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    width: "10%",
                  }}
                >
                  {n.id}
                </td>
                <td
                  style={{
                    verticalAlign: "middle",
                    width: "5%",
                  }}
                >
                  <Button
                    size="sm"
                    variant={
                      !(conversion === n.geometryType) ? "dark" : "warning"
                    }
                    disabled={!(conversion === n.geometryType)}
                    style={{
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      handleClick(n.id, projectId, murl, updateField);
                    }}
                  >
                    <MdOutlineSystemUpdateAlt color="white" />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
};
export default Layers;
