import { useContext, useState, useEffect } from "react";
import { Context } from "./Context";

import Toast from "react-bootstrap/Toast";
const Messages = () => {
  const [context] = useContext(Context);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (context?.message?.length > 0) setShow((show) => !show);
  }, [context]);

  return (
    <div
      style={{
        position: "absolute",
        bottom: "50px",
        right: "50px",
        zIndex: 9999,
        float: "right",
      }}
    >
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
        bg={context?.status}
        className="p-0 rounded-1 "
        style={{ backgroundColor: "white", opacity: "75%" }}
      >
        <Toast.Header>
          <strong>{context?.head}</strong>
        </Toast.Header>
        <Toast.Body className="rounded-1" style={{ backgroundColor: "white" }}>
          {context?.message}
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default Messages;
