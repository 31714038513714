import Main from "./Components/Main";
import Login from "./Components/Authentication/Login";
import Elogin from "./Components/Authentication/Elogin";
//import Logout from "./Components/Utilities/Logout";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" index element={<Main />} />
        {/* <Route exact path="/logout" element={<Logout />} /> */}
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/elogin" element={<Elogin />} />
      </Routes>
    </>
  );
}

export default App;
