import Avatar from "react-avatar";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const User = () => {
  const username = localStorage.getItem("user");
  //const orgId = localStorage.getItem("orgId");
  const expires = localStorage.getItem("expires");

  const expirationDate = dayjs
    .unix(expires / 1000)
    .format("MM-DD-YYYY hh:mm a")
    .toString();

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header>
        <b>User:</b> {username}
        <br />
        <b>Expires:</b> {expirationDate}
      </Popover.Header>
      <Popover.Body>
        <ListGroup>
          <Link to="/logout">
            <ListGroup.Item
              action
              onClick={() => {
                localStorage.removeItem("eapi-token");
                window.location = `https://gis.thompsoncs.net/arcgis/sharing/rest/oauth2/authorize?client_id=${process.env.REACT_APP_ECLIENT_ID}&response_type=token&redirect_uri=${process.env.REACT_APP_E_REDIRECT_URL}&expires=120`;
              }}
            >
              Logout
            </ListGroup.Item>
          </Link>
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <Avatar name={username} size="35" color="#f8991d" />
    </OverlayTrigger>
  );
};

export default User;
