import { useState, useEffect } from "react";
import { Context } from "./Context";
import Container from "react-bootstrap/Container";
import Navigation from "./Navigation/Navigation";
import Projects from "./Projects/Projects";
//
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Features from "./Features/Features";
//import Logout from "./Utilities/Logout";

import Messages from "./Messages";

const Main = () => {
  const [context, setContext] = useState();
  const [project, setProject] = useState();

  const token = localStorage.getItem("eapi-token");

  useEffect(() => {
    if (!token) {
      window.location = `https://gis.thompsoncs.net/arcgis/sharing/rest/oauth2/authorize?client_id=${process.env.REACT_APP_ECLIENT_ID}&response_type=token&redirect_uri=${process.env.REACT_APP_E_REDIRECT_URL}&expires=120`;
    }
  }, [token]);

  return (
    <Context.Provider value={[context, setContext]}>
      <Navigation />
      <Container className="p-4 m-auto">
        <Row>
          <Col sm={3}>
            <Projects project={project} setProject={setProject}></Projects>
          </Col>
          <Col sm={9}>
            <Row>
              <Features project={project} />
            </Row>
          </Col>
        </Row>
        <Messages />
      </Container>
    </Context.Provider>
  );
};

export default Main;
