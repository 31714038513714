import { useContext } from "react";
import { Context } from "../Context";
import updateFeatureClass from "../Utilities/UpdateFeatureClass";
import { MdDeleteForever } from "react-icons/md";
import Button from "react-bootstrap/Button";

const Clear = ({ url, projectId, updateField, update, setUpdate }) => {
  const [, setContext] = useContext(Context);

  const handleClick = async () => {
    const data = await updateFeatureClass(null, projectId, null, updateField);

    if (data) {
      setContext({
        head: "Success",
        message: "Updated Successfully",
        status: "success",
      });
      setUpdate(() => !update);
      return;
    }

    setContext({
      head: "Failure",
      message: "Update Failed",
      status: "danger",
    });
    setUpdate(() => !update);
    return;
  };
  return (
    <Button
      variant="danger"
      style={{ color: "white" }}
      title="Remove URL"
      disabled={url ? false : true}
      onClick={() => {
        handleClick();
      }}
    >
      <MdDeleteForever size={21} />
    </Button>
  );
};

export default Clear;
