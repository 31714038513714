import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";

const updateFeatureClass = async (
  layerId,
  projectId,
  layerUrl,
  updateField
) => {
  const token = localStorage.getItem("eapi-token");

  const newUrl =
    layerId?.toString() && layerUrl ? `${layerUrl}/${layerId}` : "";

  try {
    const attributes = {};
    attributes["objectid"] = projectId;
    attributes[updateField] = newUrl;

    let project_config_url = process.env.REACT_APP_PROJECT_CONFIG_URL;

    const layer = new FeatureLayer({
      url: project_config_url,
      apiKey: token,
    });

    const features = new Graphic({ attributes: attributes });
    const applied = await layer.applyEdits({ updateFeatures: [features] });

    if (applied?.updateFeatureResults?.length > 0) return true;

    throw new Error(`Unable to update ${updateField}`);
  } catch (error) {
    console.error(error);

    return false;
  }
};

export default updateFeatureClass;
