import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const parts = {};
    const hash = window.location.hash;
    const hashParts = hash.split("&");

    hashParts.forEach((h) => {
      return (parts[h.split("=")[0].replace("#", "")] = h.split("=")[1]);
    });

    const getUser = async () => {
      const url = new URL(
        "/arcgis/sharing/rest/community/self",
        "https://gis.thompsoncs.net"
      );

      const params = {
        f: "json",
        token: parts.access_token,
      };

      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );

      try {
        const keyCheck = await fetch(url);
        const appInfo = await keyCheck.json();
        const now = new Date();
        const expirationTime = now.setSeconds(
          now.getSeconds() + parts.expires_in
        );
        localStorage.setItem("eapi-token", parts.access_token);
        localStorage.setItem("expires", expirationTime);
        localStorage.setItem("user", appInfo.username);
        localStorage.setItem("orgId", appInfo.orgId);
        navigate("/");
      } catch (error) {
        console.error(error);
        navigate("/");
      }
    };

    if (parts.access_token) {
      console.log(hashParts);
      getUser();
    }
  }, [navigate]);
};

export default Login;
