import { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import { Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
//import Logout from "../Utilities/Logout";

const Projects = ({ project, setProject }) => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("eapi-token");
  const platform = localStorage.getItem("platform");

  useEffect(() => {
    const getData = async (token) => {
      setLoading(true);
      let project_config_url = process.env.REACT_APP_PROJECT_CONFIG_URL;
      let outfields = "objectid,name";

      const queryUrl = `${project_config_url}/query`;
      const url = new URL(queryUrl);

      const params = {
        where: "1=1",
        returnGeometry: "false",
        outFields: outfields,
        f: "json",
        orderByFields: "name",
        token: token,
      };

      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );

      try {
        const request = await fetch(url);
        const json = await request.json();

        if (json?.error) {
          localStorage.removeItem("eapi-token");
          window.location = `https://gis.thompsoncs.net/arcgis/sharing/rest/oauth2/authorize?client_id=${process.env.REACT_APP_ECLIENT_ID}&response_type=token&redirect_uri=${process.env.REACT_APP_E_REDIRECT_URL}&expires=120`;
        }

        if (json?.features && json?.features?.length) {
          setProject(
            json.features[0].attributes.OBJECTID ||
              json.features[0].attributes.OBJECTID
          );
          setLoading(false);
          setData(json.features);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    if (token) {
      getData(token);
    }
  }, [token, platform, setProject]);

  return (
    <Card className="p-0" style={{ maxHeight: "600px" }}>
      <Card.Header>
        <Card.Title>Projects</Card.Title>
      </Card.Header>
      <Card.Body className="overflow-y-auto p-0">
        {loading && <Spinner></Spinner>}
        <ListGroup variant="flush" className="m-0">
          <ListGroup.Item variant="dark" key={999999999}>
            <Form.Control
              value={search}
              type="text"
              id="project-search"
              placeholder="Search"
              aria-describedby="projectSearch"
              autoComplete="none"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </ListGroup.Item>
          {data
            ?.filter((s) => {
              return search
                ? s.attributes.name.toUpperCase().includes(search.toUpperCase())
                : s;
            })
            .map((m) => {
              return (
                <ListGroup.Item
                  as="button"
                  action
                  key={m.attributes.OBJECTID || m.attributes.objectid}
                  active={m.attributes.objectid === project}
                  onClick={() => setProject(m.attributes.objectid)}
                >
                  {m.attributes.name}
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default Projects;
