import { useEffect, useState } from "react";
import Map from "@arcgis/core/Map.js";
import MapView from "@arcgis/core/views/MapView.js";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import esriConfig from "@arcgis/core/config.js";
import LayerList from "@arcgis/core/widgets/LayerList.js";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol.js";
import Card from "react-bootstrap/Card";

const EMap = ({ roads, boundaries, zones, apiKey }) => {
  esriConfig.apiKey = apiKey;
  const [showMap, setShowMap] = useState(true);
  useEffect(() => {
    setShowMap(true);
    const roadStyle = {
      type: "unique-value",
      legendOptions: {
        title: "Roads",
      },
      field: "owner_type",
      uniqueValueInfos: [
        {
          value: "Municipality",
          label: "Municipality",
          symbol: {
            type: "simple-line",
            color: "#ffa500",
            width: "1.75px",
            style: "short-dash",
          },
        },
        {
          value: "County",
          label: "County",
          symbol: {
            type: "simple-line",
            color: "#008000",
            width: "1.75px",
            style: "short-dash",
          },
        },
        {
          value: "State",
          label: "State",
          symbol: {
            type: "simple-line",
            color: "#000080",
            width: "1.75px",
            style: "short-dash",
          },
        },
        {
          value: "Federal",
          label: "Federal",
          symbol: {
            type: "simple-line",
            color: "#000000",
            width: "1.75px",
            style: "short-dash",
          },
        },
        {
          value: "Private",
          label: "Private",
          symbol: {
            type: "simple-line",
            color: "#FF0000",
            width: "1.75px",
            style: "short-dash",
          },
        },
        {
          value: "Other",
          label: "Other",
          symbol: {
            type: "simple-line",
            color: "#808080",
            width: "1.75px",
            style: "short-dash",
          },
        },
      ],
    };

    const muniSimpleFill = new SimpleFillSymbol({
      color: [78, 78, 78, 0.5],
      style: "solid",
      outline: {
        color: [0, 0, 0, 0.9],
        width: 2,
      },
    });

    const countySimpleFill = new SimpleFillSymbol({
      color: [0, 0, 0, 0],
      style: "solid",
      outline: {
        color: [0, 0, 0, 0.9],
        width: 2,
      },
    });

    const boundStyle = {
      type: "unique-value",
      legendOptions: {
        title: "Boundaries",
      },
      field: "bound_type",
      uniqueValueInfos: [
        {
          value: "Municipality",
          label: "Municipality",
          symbol: muniSimpleFill,
        },
        {
          value: "County",
          label: "County",
          symbol: countySimpleFill,
        },
      ],
    };

    const zoneStyle = {
      type: "simple",
      symbol: {
        type: "simple-fill",
        color: [0, 0, 0, 0.05],
        style: "solid",
        outline: {
          color: [248, 24, 148, 0.75],
          width: 1.25,
        },
      },
    };

    let roadLayer;
    let zoneLayer;
    let boundLayer;

    console.log(roads);

    if (roads) {
      roadLayer = new FeatureLayer({
        url: roads,
        spatialReference: 3857,
        renderer: roadStyle,
        apiKey: apiKey,
      });
    }
    if (boundaries) {
      boundLayer = new FeatureLayer({
        url: boundaries,
        spatialReference: 3857,
        renderer: boundStyle,
        apiKey: apiKey,
      });
    }

    if (zones) {
      zoneLayer = new FeatureLayer({
        url: zones,
        spatialReference: 3857,
        renderer: zoneStyle,
        apiKey: apiKey,
      });
    }

    const myMap = new Map({
      basemap: "arcgis/topo",
    });

    if (boundLayer) {
      myMap.add(boundLayer);
    }
    if (zoneLayer) {
      myMap.add(zoneLayer);
    }
    if (roadLayer) {
      myMap.add(roadLayer);
    }

    const view = new MapView({
      map: myMap,
      container: "mapView",
    });

    let layerList = new LayerList({
      view: view,
    });

    view.ui.add(layerList, {
      position: "top-right",
    });

    if (boundLayer) {
      boundLayer
        .when(() => {
          return boundLayer.queryExtent();
        })
        .then((r) => {
          view.goTo(r.extent);
        });
    } else if (roadLayer) {
      roadLayer
        .when(() => {
          return roadLayer.queryExtent();
        })
        .then((r) => {
          view.goTo(r.extent);
        });
    } else if (zoneLayer) {
      zoneLayer
        .when(() => {
          return zoneLayer.queryExtent();
        })
        .then((r) => {
          view.goTo(r.extent);
        });
    } else {
      setShowMap(false);
    }
  }, [roads, boundaries, zones, apiKey, showMap, setShowMap]);

  if (!showMap) {
    return "There is no data to display on a map at this time.";
  }

  if (showMap) {
    return (
      <Card>
        <Card.Body>
          <div id="mapView" style={{ height: "600px" }}></div>
        </Card.Body>
      </Card>
    );
  }
};

export default EMap;
