import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { TbMapPlus, TbUserFilled } from "react-icons/tb";
import { IoBusiness } from "react-icons/io5";
import { MdOutlinePublic } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";
import Layers from "./Layers";

const LayerPicker = ({
  projectId,
  name,
  title,
  type,
  updateField,
  update,
  setUpdate,
}) => {
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState("");

  const token = localStorage.getItem("eapi-token");

  const handleClose = () => {
    setData([]);
    setSearch("");
    setShow(false);
  };

  useEffect(() => {
    const getFeatures = async () => {
      setLoading(true);
      const allFeatures = [];
      let cont = true;
      let start = 1;

      while (cont) {
        const project_config_url = "https://gis.thompsoncs.net";
        const project_path = "/arcgis/sharing/rest/search";

        const url = new URL(project_path, project_config_url);

        const params = {
          q: "NOT owner: esri_livingatlas",
          filter: `type: "Feature Service"`,
          num: 50,
          start: start,
          f: "json",
          sortField: "modified",
          sortOrder: "desc",
          displaySublayers: true,
          token: token,
        };

        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key])
        );
        console.log(url);
        try {
          const response = await fetch(url, { cache: "no-cache" });
          const json = await response.json();
          console.log(json);
          start = json?.nextStart;
          if (json?.nextStart < 0) {
            cont = false;
          }
          allFeatures.push(...json.results);
        } catch (error) {
          cont = false;
          setLoading(false);
          setError("There was a problem accessing ArcGIS Online");
          console.error(error);
          return;
        }
      }
      setLoading(false);
      setData(allFeatures);
    };
    if (show) {
      getFeatures();
    }
  }, [token, show]);

  return (
    <>
      <Modal size="lg" fullscreen centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Row>
            <Modal.Title>
              Available Feature Classes for {name} - {title} - ({data.length})
            </Modal.Title>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              placeholder="Search"
              value={search}
              id="feature-search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></Form.Control>
          </Form>
          {loading ? (
            <Spinner variant="primary" />
          ) : error ? (
            <Modal.Title>{error}</Modal.Title>
          ) : (
            <Row>
              {data
                .filter((u) => {
                  if (search?.length > 0) {
                    return (
                      u?.name?.toLowerCase().includes(search?.toLowerCase()) ||
                      u?.title?.toLowerCase().includes(search?.toLowerCase())
                    );
                  }
                  return u;
                })
                .map((m, idx) => {
                  return (
                    <Card key={idx} className="my-2">
                      <Card.Body className="mt-2">
                        <Row className="px-0">
                          <Col
                            sm={3}
                            className="d-flex align-items-center justify-content-center p-0 m-0"
                          >
                            <Image
                              rounded
                              src={`${m.url}/info/thumbnail`}
                              alt={`Thumbnail for ${m.name}`}
                              style={{ height: "200px" }}
                              onError={(e) =>
                                (e.currentTarget.src = "./non.png")
                              }
                            />
                          </Col>
                          <Col xs={9}>
                            <Card.Subtitle>
                              <b>{m.title}</b> - {m.name || m.title} -{" "}
                              {m.access === "org" ? (
                                <IoBusiness
                                  color="orange"
                                  title={`Shared with ${m.access}`}
                                />
                              ) : m.access === "public" ? (
                                <MdOutlinePublic
                                  color="orange"
                                  title={`Shared with ${m.access}`}
                                />
                              ) : m.access === "shared" ? (
                                <FaHandshake color="orange" title={`Shared`} />
                              ) : (
                                <TbUserFilled color="orange" title="Private" />
                              )}
                              - {m.owner}
                            </Card.Subtitle>
                            <Form className="my-2">
                              <Form.Control
                                placeholder="Search"
                                defaultValue={m.url}
                              ></Form.Control>
                            </Form>
                            <Layers
                              data={m.sublayers}
                              projectId={projectId}
                              type={type}
                              murl={m.url}
                              setShow={setShow}
                              update={update}
                              updateField={updateField}
                              setUpdate={setUpdate}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  );
                })}
            </Row>
          )}
        </Modal.Body>
      </Modal>
      <Button
        style={{ color: "white" }}
        title="Update URL"
        onClick={() => {
          setSearch("");
          setShow(true);
        }}
      >
        {loading ? <Spinner /> : <TbMapPlus />}
      </Button>
    </>
  );
};

export default LayerPicker;
