import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { IoAnalyticsOutline } from "react-icons/io5";
import {
  TbPolygon,
  TbDeviceProjector,
  TbPlugConnected,
  TbWorldCheck,
  TbTableColumn,
} from "react-icons/tb";
import { MdLayers, MdLayersClear } from "react-icons/md";

const Checks = ({ url, type, setData, data, title }) => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState();
  const [cols, setCols] = useState();
  const token = localStorage.getItem("eapi-token");

  useEffect(() => {
    const requiredFields = async (fieldsFromService) => {
      let required = [];
      let fields = [];

      fieldsFromService.map((m) => {
        return fields.push(m.name);
      });

      switch (title) {
        case "Boundary":
          required.push("bound_elig", "bound_name");
          break;
        case "Roads":
          required.push("road_name", "road_maint", "owner_type", "road_elig");
          break;
        case "Zones":
          required.push("zone_name");
          break;
        default:
          break;
      }

      const allIncluded = required.every((v) => fields.includes(v));

      const missing = required.filter((v) => !fields.includes(v));

      setCols({ allIncluded, missing, required });
    };

    const getInfo = async () => {
      setLoading(true);

      const infoUrl = new URL(url);

      const params = {
        f: "json",
        token: token,
      };

      Object.keys(params).forEach((key) =>
        infoUrl.searchParams.append(key, params[key])
      );

      let json = {};
      try {
        const response = await fetch(infoUrl, { cache: "no-cache" });
        json = await response.json();
        if (
          json?.error &&
          (json?.error?.code > 300 || json?.error?.code > 200)
        ) {
          setErr(true);
          setLoading(false);
          return;
        }
        setErr(false);
        setLoading(false);

        setData(json);
        requiredFields(json.fields);
      } catch (error) {
        console.error(error);
        setLoading(false);
        setErr(true);
      }
    };
    if (url) {
      getInfo();
    }
  }, [url, title, token, setData]);

  return (
    <>
      {loading ? (
        <Spinner variant="primary" />
      ) : (
        <>
          {!err ? (
            <TbWorldCheck color="green" title="Feature Layer Available" />
          ) : (
            <TbPlugConnected color="red" title="Feature Layer Unavailable" />
          )}
          {!err && (
            <>
              {data?.type === "Feature Layer" ? (
                <MdLayers color="green" title="Item is a Feature Layer" />
              ) : (
                <MdLayersClear
                  color="red"
                  title="Item is not a Feature Layer"
                />
              )}
            </>
          )}
          {!err && type === "line" && (
            <IoAnalyticsOutline
              color={
                data?.geometryType === "esriGeometryPolyline" ? "green" : "red"
              }
              title={
                data?.geometryType === "esriGeometryPolyline"
                  ? "Item has a Line Geometry"
                  : "Item does not have a Line Geometry"
              }
            />
          )}
          {!err && type === "polygon" && (
            <TbPolygon
              color={
                data?.geometryType === "esriGeometryPolygon" ? "green" : "red"
              }
              title={
                data?.geometryType === "esriGeometryPolygon"
                  ? "Item has a Polygon Geometry"
                  : "Item does not have a Polygon Geometry"
              }
            />
          )}
          {!err && (
            <TbTableColumn
              color={cols?.allIncluded ? "green" : "red"}
              title={
                cols?.missing?.length > 0
                  ? `Missing required fields ${cols?.missing?.join(", ")}`
                  : `Service has required columns ${cols?.required?.join(", ")}`
              }
            />
          )}
          {!err && (
            <>
              {data?.extent?.spatialReference?.latestWkid === 3857 ||
              data?.extent?.spatialReference?.wkid === 3857 ||
              data?.extent?.spatialReference?.latestWkid === 102100 ||
              data?.extent?.spatialReference?.wkid === 102100 ? (
                <TbDeviceProjector
                  color="green"
                  title={`Item is in 3857 or 102100`}
                />
              ) : (
                <TbDeviceProjector
                  color="orange"
                  title={`Item is in ${data?.extent?.spatialReference?.wkid} or ${data?.extent?.spatialReference?.latestWkid} but should be in 3587 or 102100`}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Checks;
