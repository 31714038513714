import { useState, useEffect } from "react";
import Checks from "../Utilities/Checks";
import LayerPicker from "./LayerPicker";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Clear from "./Clear";

const Feature = ({
  url,
  name,
  title,
  type,
  required,
  projectId,
  update,
  setUpdate,
}) => {
  const [featureName, setFeatureName] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    setFeatureName(data?.name);

    if (!url) {
      setFeatureName(null);
    }
  }, [url, data]);

  let updateField;
  switch (title) {
    case "Roads":
      updateField = "roadfs";
      break;
    case "Boundary":
      updateField = "boundaryfs";
      break;
    case "Zones":
      updateField = "zonefs";
      break;
    case "Parcels":
      updateField = "parcelfs";
      break;
    case "Progress":
      updateField = "roads_progress";
      break;
    default:
      break;
  }

  return (
    <>
      <tr>
        <th
          rowSpan={2}
          style={{
            verticalAlign: "middle",
            textAlign: "left",
            borderBottom: "2px solid #DDD",
          }}
        >
          {title} {required ? "*" : ""}
        </th>

        <td
          style={{
            borderBottom: "none",
          }}
        >
          <small>
            <b>{featureName}</b>
          </small>
        </td>
        <td
          rowSpan={2}
          className="p-2"
          style={{
            verticalAlign: "middle",
            textAlign: "center",
            borderBottom: "2px solid #DDD",
          }}
        >
          {url && (
            <Checks
              url={url}
              type={type}
              setData={setData}
              data={data}
              title={title}
            />
          )}
        </td>
        <td
          rowSpan={2}
          style={{
            verticalAlign: "middle",
            textAlign: "center",
            borderBottom: "2px solid #DDD",
          }}
        >
          <ButtonGroup>
            <LayerPicker
              name={name}
              projectId={projectId}
              type={type}
              title={title}
              updateField={updateField}
              update={update}
              setUpdate={setUpdate}
            />
            <Clear
              url={url}
              projectId={projectId}
              updateField={updateField}
              update={update}
              setUpdate={setUpdate}
            ></Clear>
          </ButtonGroup>
        </td>
      </tr>
      <tr>
        <td
          style={{
            color: "#f8991d",
            maxWidth: "450px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            borderBottom: "2px solid #DDD",
          }}
        >
          <small title={url}>
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          </small>
        </td>
      </tr>
    </>
  );
};

export default Feature;
